<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
  components: {
    Layout,
    EasyDataTable,
    // ReportStatus
  },
  data() {
    return {
      searchValueTable: "",
      addStaff: false,
      selectFilterSort: null,
      app_URL: process.env.VUE_APP_URL,
      headers: [
        { text: "Full Name", value: "disclosure" },
        { text: "Email", value: "email" },
        // { text: "Status", value: "disclosure.formStatus" },
        { text: "Action", value: "actionBtn" },
      ],

      items: [],
      arrItem: [],
    };
  },
  mounted() {
    this.getAllApplicant();
  },
  created() {
    this.getAllApplicant();
  },
  methods: {
    getAllApplicant() {
      axios.post(this.app_URL + "api/getAllApplicant").then(res => {
        this.arrItem = res.data;
      })
    },
    encode(id) {
      return encodeURIComponent(
        CryptoJS.AES.encrypt(String(id), "Secret Passphrase")
      );
    }
  },


};
</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3">
        <div class="col">
          <h2 class="font-size-22 text-theme-dark">All Applicants List</h2>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row mb-3 align-items-center">
            <div class="col-lg-8 mt-3 mt-md-0">

            </div>
            <div class="col-lg-4 text-end">
              <input type="text" v-model="searchValueTable" placeholder="Search..."
                class="customInputForm form-control" />
            </div>
          </div>
          <EasyDataTable :headers="headers" :items="arrItem" :search-value="searchValueTable" border-cell
            theme-color="#df6a0d" :rows-per-page="10" buttons-pagination
            table-class-name="table-custom-style noWrapHeading">
            <template #item-disclosure="{ disclosure }">
              <p class="mb-0 text-nowrap fw-medium">{{ disclosure ? disclosure.firstName : '' }} {{ disclosure
                ? disclosure.surName : '' }}</p>
            </template>
            <!-- <template #item-status="{ formStatus }">
              <p class="mb-0 text-nowrap fw-medium">{{ formStatus }}</p>
            </template> -->
            <template #item-actionBtn="{ id }">
              <router-link :to="`/allApplicants/${this.encode(id)}`" class="btn btn-sm btn-theme-dark">
                <i class="mdi mdi-eye"></i>
                View
              </router-link>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </main>
  </Layout>
</template>
